<template>
	<div>
		<!-- <a-layout class="custom-layout"> -->
			<!-- <a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" class="custom-sider">
				<div class="custom-box">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
						</div>

				</div>
			</a-layout-sider> -->
			<!-- <a-layout-content style="padding: 0px 20px; background: #F7F8FA; min-height: calc(100vh - 100px);"> -->
				<div>
					<router-view></router-view>
				</div>
			<!-- </a-layout-content> -->
		<!-- </a-layout> -->
		
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menus: [
					{
						name: '使用中',
						icon: 'iconsubnav_miniprogram01',
						key: 'shop_online',
						link: '/shop'
					},
					/*{
						name: '即将到期',
						icon: 'iconsubnav_miniprogram021',
						key: 'shop_notice',
						link: '/shop/notice'
					},
					{
						name: '试用中',
						icon: 'iconshiyong',
						key: 'shop_test',
						link: '/shop/test'
					},
					{
						name: '已失效店铺',
						icon: 'iconsubnav_miniprogram03',
						key: 'shop_expire',
						link: '/shop/expire'
					},*/
					{
						name: '客服',
						icon: 'iconsubnav_miniprogram03',
						key: 'shop_kefu',
						link: '/shop/kefu'
					},
					{
						name: '免认证',
						icon: 'iconsubnav_shop01',
						key: 'shop_free',
						link: '/shop/free'
					},
          {
            name: '插件中心',
            icon: 'icontop_nav06',
            key: 'shop_plugin',
            link: '/shop/plugin'
          },
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>

<style>
	
</style>
